.first_tech {
    background-color: #000630;
    width: 100%;
    align-items: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 7vh 0 1vh 0
}

.second_tech {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    object-fit: contain;
    position: absolute;
    padding-top: 8vh;
}

.third_tech {
    position: relative;
    justify-content: center;
    z-index: 1;
    background-color: #f3f2f2;
    width: 100%;
    align-items: center;
    object-fit: contain;
    display: grid;
    grid-template-columns: repeat(2, 50vw);
    flex-direction: column;
    position: absolute;
    padding-top: 3vh;
}

.fourth_tech {
    background-color: #f9f9f9;
    height: auto;
    width: 100%;
    align-items: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-top: 12vh;
    padding-bottom: 3vh;
}

.fifth_tech {
    background-color: #000630;
    height: 15vh;
    width: 100%;
}

.header_pipeline {
    font-family: DINCondensed;
    font-size: 4vw;
    padding-top: 0.5vw;
    padding-bottom: 0vh;
    text-transform: uppercase;
    text-shadow: .15vw .05vw rgba(0, 0, 0, 0.2);
    text-align: left;
    line-height: 1;
}

@media (orientation: portrait) {
    .header_pipeline {
        font-size: 7vw;
    }
}

.header2_pipeline {
    font-family: DINCondensed;
    font-size: 3.5vw;
    padding-top: 0.5vw;
    padding-bottom: 0vh;
    text-transform: uppercase;
    text-shadow: .15vw .05vw rgba(0, 0, 0, 0.2);
    text-align: left;
    line-height: 1;
}

@media (orientation: portrait) {
    .header2_pipeline {
        font-size: 7vw;
    }
}

.body_no_padding {
    display: flex;
    flex-direction: column;
    color: #353535;
    padding-top: 0;
    padding-bottom: 3vh;
    font-family: Futura;
    fontSize: clamp(1rem, 3vw);
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: middle;
    overflow-x: hidden;
    margin: 0 auto;
}

.tech-logos {
   display: flex;
   align-items: center;
   justify-content: space-between; /* Spreads the logos evenly */
   padding-bottom: 0;
   max-width: 70vw;
   overflow: hidden;
}

.tech-logo {
   max-height: 30px;
   max-width: 10vw;
   width: auto;
   flex-shrink: 1;
   object-fit: contain; /* Ensures logos fit within their container */
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: middle;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0 auto;
}

.carousel {
    margin: 0 5px 0 5px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    padding: 10px 0px 10px 0px;
    scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
    display: none;
}

.carousel-item, .carousel-item2 {
    overflow: hidden;
    flex: none;
    height: 100%;
    position: relative;
    transition: transform 0.3s ease;
    scroll-snap-align: start;
}

.carousel-item {
    position: relative;
    border-radius: 15px;
    width: 280px;
}


@media (orientation: portrait) {
    .carousel-item {
        width: 180px;
    }
}

.carousel-item img {
    border-radius: 15px;
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.carousel-item:hover {
    transform: scale(1.05);
    z-index: 2;
}

.carousel-item2 {
    width: 160px;
    flex: none;
    scroll-snap-align: start;
    overflow: hidden;
}

.carousel-item2 img {
    width: 100%;
    height: auto;
    display: block;
}

.carousel-item::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    opacity: 1;
    transition: opacity 0.3s ease;
}

.carousel-item:hover::before {
    opacity: 0.5;
}

.carousel-text {
    font-family: Futura;
    font-size: 20px;
    color: #efefef;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 5px;
    text-shadow: .1vw .1vw rgba(0, 0, 0, 0.5);
}

@media (orientation: portrait) {
    .carousel-text {
        font-size: 14px;
    }
}


.carousel-plus {
    position: absolute;
    bottom: 13px;
    right: 13px;
    height: 35px !important;
    width: 35px !important;
    z-index: 2;
}

@media (orientation: portrait) {
    .carousel-plus {
        height: 22px !important;
        width: 22px !important;
    }
}


.carousel {
  display: flex;
  gap: 10px;
}

.carousel-item {
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: auto;
  gap: 2vh;
  width: 80%;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vw;
  background-color: white;
  box-shadow: 1.5px 1.5px 0px rgba(0, 0, 0, 0.14);
}

.grid-item.dark {
  background-color: #1a1a2e;
  color: white;
}

.grid-item h3 {
  font-family: DINCondensed;
  text-transform: uppercase;
  margin-top: 0px;
  font-size: 2vw;
  margin-bottom: 15px;
}


@media (orientation: portrait) {
    .grid-item h3 {
        font-size: 7vw; /* Adjust font size for portrait mode */
    }
}

.grid-item p {
  font-family: Futura;
  fontSize: 3vw;
  font-size: 1rem;
  margin: 5px 2vw 15px 2vw;
  text-align: center;
}

/* VIRTUAL REALITY text */

.vr-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.vr-content {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: white;
}

.vr-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-shadow: .15vw .05vw rgba(0, 0, 0, 0.7);
  font-family: DINCondensed;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  transform: translate(-80%, -240%);
  font-size: 8vw;
}

.vr-text.small {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-shadow: .15vw .05vw rgba(0, 0, 0, 0.7);
  font-family: DINCondensed;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  transform: translate(-106%, -230%);
  font-size: 6vw;
}

/* Portrait orientation styles */
@media (orientation: portrait) {
  .vr-text {
    font-size: 13vw;
    transform: translate(-90%, -270%);
  }
  .vr-text.small {
    font-size: 13vw;
    transform: translate(-92%, -215%);
  }
}
/* VIRTUAL PRODUCTION text */

.vp-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.vp-content {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: white;
}

.vp-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-shadow: .15vw .05vw rgba(0, 0, 0, 0.7);
  font-family: DINCondensed;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  transform: translate(-80%, -240%);
  font-size: 8vw;
}

.vp-text.small {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-shadow: .15vw .05vw rgba(0, 0, 0, 0.7);
  font-family: DINCondensed;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  transform: translate(-70%, -230%);
  font-size: 6vw;
}

/* Portrait orientation styles */
@media (orientation: portrait) {
  .vp-text {
    font-size: 13vw;
    transform: translate(-90%, -270%);
  }
  .vp-text.small {
    font-size: 11vw;
    transform: translate(-70%, -245%);
  }
}




@media (orientation: portrait) {
    .grid {
        grid-template-columns: 1fr; /* Switches to a single column */
        width: 95%;
    }
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-shadow: .15vw .05vw rgba(0, 0, 0, 0.2);
  font-family: DINCondensed;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
}

.carousel {
  display: flex;
  gap: 10px;
}

.carousel-item {
  cursor: pointer;
}

