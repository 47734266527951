.wrapper {
    flex-direction: column;
    position: relativs;
}

.columns-container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 30vw);
    gap: 5vw;
    justify-content: left;
    flex-direction: column;
}

.image-style {
  width: 36vw;
  max-width: 80vw;
  padding-top: 3vh;
}

.games-image {
    position: relative;
    padding: 15vh 0 0 0;
    width: 50vw;
}

.fsu-image {
    position: relative;
    padding: 15vh 0 0 0;
    width: 50vw;
    zIndex: 0;
    opacity: 30%;
    transform: translate(0%, -8%);
}

@media (orientation: portrait) {
    .fsu-image {
        width: 100vw;
        transform: translate(0%, -3%);
    }
}

@media (orientation: portrait) {
    .games-image {
        width: 70vw;
    }
}

.fsu_wrapper {
    transform: translate(0%, -175%);
    position: absolute;
    zIndex: 1;
    padding: 0 10vw 3vh 10vw;
}

@media (orientation: portrait) {
    .fsu_wrapper {
        transform: translate(0, -150%);
    }
}

.bodyText {
    font-family: Futura;
    font-size: 1.6vw;
    color: #efefef;
    margin: 5vw;
}

@media (orientation: portrait) {
    .bodyText {
        font-size: 3.5vw;
    }
}

.first_home {
    background: #000630;
    height: 75vh;
    width: 100%;
    align-items: center;
    object-fit: contain;
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: center;
}

.second_home {
    background: #000630;
    height: 55vh;
    width: 100vw;
    align-items: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5vh 0 15vh 0;
}

@media (orientation: portrait) {
    .second_home {
        height: 50vh;
        padding: 0 0 0 0;
    }
}

.mission {
    background: #000630;
    min-height: 88vh;
    width: 84vw;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 40vh 8vw 0 8vw;
}

@media (orientation: portrait) {
    .mission {
        min-height: 82vh;
        padding: 40vh 20vw 0 8vw;
    }
}

.third_home {
    background-color: #000630;
    height: 60vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
}

.phone_home {
    background-color: #000630;
    width: 100%;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 0 2vh 0;
}

.laptop_home {
    height: 60vh;
    padding: 0vh 0 10vh 0;
    background-color: #000630;
    width: 100%;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: relative;
}

.fourth_home {
    background-color: #000630;
    width: 100%;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 0 2vh 0;
}


.fifth_home {
    background-color: black;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.sixth_home {
    background-color: #000630;
    width: 100%;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.logo-row {
  display: flex;
  justify-content: center; /* Center the logos horizontally */
  align-items: center;    /* Center the logos vertically if needed */
  gap: 3vw;               /* 3vw gap between logos */
  padding: 20px;          /* Optional padding */
}

.logo-image {
  width: 15vw;
  height: auto;
  opacity: .25;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.05);
  opacity: 0.5;
}

.mission_text {
    color: white;
    font-family: DINCondensed;
    font-size: 5vw;
    text-transform: uppercase;
    text-shadow: .15vw .05vw rgba(0, 0, 0, 0.2);
}

@media (orientation: portrait) {
    .mission_text {
        font-size: 8vw;
    }
}

.ticker {
  width: 100%;
  height: 150%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  /* Start invisible, then animate in */
  opacity: 0;
  animation: fadeInTicker 2s ease forwards;
}

@keyframes fadeInTicker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

.ticker__move {
  display: inline-block;
  /* This total animation time sets the scroll speed. Adjust to your liking. */
  animation: ticker-scroll 15s linear infinite;
}

.ticker__item {
  display: inline-block;
  padding: 0 5vw; /* space between repeated text sets your gap */
  font-size: 2vw; /* your bodyText style could be here or in your .bodyText class */
  margin: 0;
  color: white;
  opacity: 60%;
}

@media (orientation: portrait) {
    .ticker__item {
        font-size: 4vw;
    }
}

/* Keyframes to shift the entire text block left by 50% */
@keyframes ticker-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    /* move left by half of the container's total width (since we duplicated text) */
    transform: translateX(-50%);
  }
}
