.video-modal {
    align-items: center;
    display: flex;
    justify-content: center;
}

.video-modal-content {
    object-fit: contain;
    display: flex;
}

.video-modal-content iframe {
    aspect-ratio: 17 / 9;
    width: 100%;
}

.desc-content {
    align-items: center;
    color: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #efefef;
}

.desc-content {
    width: 100%;
}

.desc-title {
    font-family: 'DINCondensed', sans-serif;
    font-size: 3vw;
    text-transform: uppercase;
    padding-top: 5%;
}

.desc-divider {
    width: 100%;
    color: white;
    border-bottom: 1px solid #000;
}

.desc-role {
    font-family: 'DINCondensed', sans-serif;
    font-size: 1.5vw;
    text-transform: uppercase;
    padding-top: 0;
}

.desc-description {
    font-family: 'Futura', sans-serif;
    font-size: 1vw;
    padding-left: 3vw;
    padding-right: 3vw;
}

/* Responsive styles for mobile */
@media (orientation: portrait) {
    .desc-title {
        font-size: 6vw;
        padding-bottom: 0;
    }

    .desc-role {
        font-size: 3vw;
        padding-bottom: 2vh;
    }

    .desc-description {
        font-size: 2.5vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
}


.wrapper {
    flex-direction: column;
    position: relative;
}

.first_film {
    align-items: center;
    background: #000630;
    display: flex;
    flex-direction: column;
    width: 100%;
    object-fit: contain;
    position: absolute;
    padding: 9vh 0 3vh 0;
}

.second_film {
    background-color: #f9f9f9;
    width: 100%;
    align-items: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.third_film {
    background-color: #ededed;
    width: 100%;
    align-items: center;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 9vh 0 11vh 0;
}

.video-grid {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(5, min-content);
    gap: 2vw;
    justify-content: center;
    flex-direction: column;
}

.column-container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 35vw);
    padding-bottom: 5vh;
}

.column-container > * {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

@media (orientation: portrait) {
    .column-container {
        grid-template-columns: 1fr; /* Switches to a single column */
    }
}

.column-container2 { /* Puts the first column on the bottom */
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 35vw);
    padding-bottom: 5vh;
}

.column-container2 > * {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.column-container3 { /* Doesn't switch for mobile */
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 35vw);
    padding-bottom: 5vh;
}

.column-container3 > * {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

@media (orientation: portrait) {
    .column-container2 {
        grid-template-columns: 1fr;
    }

    .column-container2 > :nth-child(1) {
        order: 2;
    }

    .column-container2 > :nth-child(2) {
        order: 1;
    }
}

.slate_background {
    align-items: center;
    aspect-ratio: 16 / 9;
    background: repeating-linear-gradient(
        45deg,
        black,
        black 2vw,
        white 2vw,
        white 4vw
    );
    border: .25vw solid black;
    box-shadow: .6vw .6vw rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    object-fit: contain;
    outline: .25vw solid white;
    width: 35vw;
}

.slate_background2 {
    aspect-ratio: 17 / 9;
    background-color: black;
    border: .25vw solid white;
    box-shadow: .6vw .6vw rgba(0, 0, 0, 0.4);
    outline: .25vw solid black;
    width: 32vw;
    overflow: hidden;
}

@media (orientation: portrait) {
    .slate_background {
        width: 80vw;
    }
}

@media (orientation: portrait) {
    .slate_background2 {
        width: 76vw;
    }
}

@media (orientation: portrait) {
    .slate_background2 {
        width: 76vw;
    }
}

.thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.thumbnail-image {
    box-shadow: .3vw .3vw rgba(0, 0, 0, 0.2);
    cursor: pointer;
    width: 10vw;
    transition: transform .5s ease, box-shadow 1s ease;
}

.thumbnail-image:hover {
    box-shadow: .4vw .4vw rgba(0, 0, 0, 0.4);
    transform: scale(1.1);
}
